// src/InternshipStudents.tsx
import React from "react";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import { Linkedin } from "react-bootstrap-icons";

import "./styles.css";

// Define the Student interface
interface Student {
  id: number;
  name: string;
  internshipCourse: string;
  mark: number;
  linkedinUrl?: string;
}

// Sample data
const students: Student[] = [
  {
    id: 1,
    name: "Krishnan K",
    mark: 8,
    internshipCourse: "Product Management Training and Internship",
    linkedinUrl: "https://www.linkedin.com/in/krishnan005k/",
  },
  {
    id: 2,
    name: "Rishi Jha",
    mark: 8,
    internshipCourse: "Product Management Training and Internship",
  },
  {
    id: 3,
    name: "Ritisa P",
    mark: 8,
    internshipCourse: "Product Management Training and Internship",
  },
  {
    id: 4,
    name: "Raj Nandini Dey",
    mark: 10,
    internshipCourse: "Product Management Training and Internship",
  },
];

const InternshipStudents: React.FC = () => {
  return (
    <Container className="my-5">
      <h1 className="mb-2 text-center">
        Our Interns: Shaping the Future Together
      </h1>
      <p className="small text-secondary mb-5">
        Discover the bright minds who have contributed to our company through
        their internships. These talented individuals have gained valuable
        experience, developed new skills, and made a meaningful impact during
        their time with us. Explore the list of our past interns and their
        journey with us!
      </p>
      <Row xs={1} sm={2} md={3} lg={3} className="g-4">
        {students.map((student) => (
          <Col key={student.id}>
            <Card className="h-100 shadow-sm">
              <Card.Body className="d-flex flex-column">
                <Card.Title className="d-flex justify-content-start align-items-center">
                  <div className="circular-profile me-2">
                    {student.name.at(0)}
                  </div>
                  {student.name}
                </Card.Title>
                <div className="text-left small text-secondary">
                  The candidate has successfully completed the course{" "}
                  <u>{student.internshipCourse}</u>. We grade their performance
                  as {student.mark} out of 10.
                </div>

                <div className="mt-3">
                  {student.linkedinUrl ? (
                    <Button
                      variant="link"
                      href={student.linkedinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-0"
                    >
                      <Linkedin />
                    </Button>
                  ) : null}
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default InternshipStudents;
