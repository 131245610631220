import { Card, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import { House, Laptop } from "react-bootstrap-icons";

import "./styles.css";

function HomeIntro() {
  return (
    <Container fluid className="container-main">
      <h2 className="heading">Ether Technology Solutions</h2>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Card
            className="card-custom"
            style={{ background: "#0095f7" }}
            onClick={() => window.open(window.location.origin + "/home.html")}
          >
            <House size={80} />
            Visit Website
          </Card>
        </Col>
        <Col xs="auto">
          <Link to="/course-list" style={{ textDecoration: "none" }}>
            <Card className="card-custom" style={{ background: "#FDA403" }}>
              <Laptop size={80} />
              Join Internship
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default HomeIntro;
